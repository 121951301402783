import React from "react";
import Head from "../../Components/Navbar/aboutComponents/head/Head";
import styles from "./about.module.css";
import logo from "../../Images/350px-IEEE_logo 1.png";
import copy from "../../Images/Copy of IEEE YP Egypt 1.png";
import sb from "../../Images/IEEE AAST Alex SB - blue 1.png";
import sac from "../../Images/Copy of IEEE SAC Egypt 1.png";
import Footer from "../../Components/Footer/Footer";
import CarouselComponent from "../../Components/Carousel/CarouselComponent";

export default function About() {
  return (
    <div>
      <Head></Head>

      <section className="p-11 lg:p-24">
        <div className="leftwhatisieee">
          <h1 className="text-4xl mb-6 font-bold lg:text-6xl ">
            What is IEEE?
          </h1>
          <div className="flex flex-col xl:flex-row">
            <ul className="lg:text-2xl font-thin xl:w-7/12">
              <li className={`${styles.leftlist} `}>
                <p>
                  IEEE is the world’s largest technical professional
                  organization dedicated to advancing technology for the benefit
                  of humanity.
                </p>
              </li>
              <li className={styles.leftlist}>
                <p>
                  Has 39 technical Societies and 7 Technical Councils
                  representing the wide range of IEEE technical interests.
                </p>
              </li>
              <li className={styles.leftlist}>
                <p>
                  Has more than 5 million documents in the IEEE Xplore® digital
                  library, with more than 15 million downloads each month.
                </p>
              </li>
              <li className={styles.leftlist}>
                <p>
                  Has an active portfolio of 1,076 standards and more than 900
                  projects under development
                </p>
              </li>
              <li className={styles.leftlist}>
                <p>
                  Sponsors more than 1,900 conferences and events in 96
                  countries.
                </p>
              </li>
            </ul>
            <div className="flex justify-center items-center mt-11 xl:w-5/12">
              <img
                className="w-7/12 rotate-90 md:w-4/12 md:rotate-90 lg:w-3/12 lg:rotate-90 xl:w-5/12 xl:rotate-180"
                src={logo}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <div className="p-11 lg:p-24">
        <div className="leftwhatisieee">
          <h1 className="text-4xl mb-6 font-bold lg:text-6xl ">
            About IEEE Egypt Section
          </h1>
          <div className="flex flex-col xl:flex-row">
            <ul className=" font-thin lg:text-2xl xl:w-7/12">
              <li className={styles.leftlist}>
                <p>
                  IEEE Egypt Section was established on September 8th, 1955 as
                  the second IEEE section outside the USA and in region 8.
                </p>
              </li>
              <li className={styles.leftlist}>
                <p>
                  More than 3,000 volunteers from Industry Professionals,
                  Students, and Academicians.
                </p>
              </li>
              <li className={styles.leftlist}>
                <p>
                  3 affinity groups (Life Members, Young Professionals, Women in
                  Engineering) and IEEE SIGHT group.
                </p>
              </li>
            </ul>
            <div className="flex justify-center items-center mt-11 xl:w-5/12">
              <img
                className="w-7/12 rotate-90 md:w-4/12 md:rotate-90 lg:w-3/12 lg:rotate-90 xl:rotate-180 xl:w-5/12"
                src={logo}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      {/* <section className="p-11 lg:p-24">
        <h1 className="text-3xl mb-6 font-semibold lg:text-6xl">
          About IEEE Young Professionals Egypt
        </h1>
        <p className="lg:text-2xl md:text-xl md:leading-7 font-thin md:mt-5 lg:leading-10">
          IEEE Young Professionals is a group of IEEE members and volunteers who
          have graduated with their first professional degree within the past 15
          years. It is an international community whose members are interested
          in elevating their professional image, expanding their global network,
          connecting with peers locally, and giving back to their community.
          Since it encompasses all members, from recent university graduates to
          experienced professionals and entrepreneurs, the group is highly
          diverse in what it has to offer. For YP Egypt, 2002, we started our
          Journey as IEEE GOLD Egypt – Graduates of the last decade. 2022 will
          mark 20 Years of achievements and continue our journey to serve the
          Engineering community.
        </p>
      </section> */}
      <section className="p-11 lg:p-24">
        <div className="leftwhatisieee">
          <h1 className="text-3xl mb-6 font-bold lg:text-5xl">
            About IEEE Young Professionals Egypt
          </h1>
          <div className="flex-col flex xl:flex-row">
            <div className="xl:w-5/12">
              <p className={`${styles.specialheading} lg:text-2xl font-thin`}>
                A group of IEEE members and volunteers who have graduated with
                their first professional degree within the past 15 years. 2022
                will mark 20 years of achievements for
                <span className=" lg:text-2xl font-semibold">
                  IEEE Young Professionals Egypt.
                </span>
              </p>
              <div className={`${styles.flexsection} lg:text-2xl font-thin`}>
                <p>
                  <span className={styles.flexspecialtext}>
                    IEEE Young Professionals Egypt
                  </span>{" "}
                  organizes five events each year:
                </p>
                <ul className={styles.flexlist}>
                  <li>
                    <p>Egyptian Engineering Day (EED)</p>
                  </li>
                  <li>
                    <p>Made in Egypt (MIE)</p>
                  </li>
                  <li>
                    <p>Volunteers Empowerment Program (VEP)</p>
                  </li>
                  <li>
                    <p>Future City (FC)</p>
                  </li>
                  <li>
                    <p>Cairo NASA Space Apps</p>
                  </li>
                </ul>
              </div>
              <ul className="lg:text-2xl font-thin xl:ml-12">
                <li className={styles.leftlist}>
                  <p>
                    More than 400 members and 70 senior members in August 2022.
                  </p>
                </li>
                <li className={styles.leftlist}>
                  <p>
                    It brings together over 20,000 innovation leaders, 80 Tech
                    companies, and 50 community partners.
                  </p>
                </li>
                <li className={styles.leftlist}>
                  <p>
                    Organized several events with IEEE Egypt technical chapters
                  </p>
                </li>
              </ul>
            </div>

            <div className="flex justify-center items-center mt-11 xl:w-5/12  xl:justify-end">
              <img
                className="w-3/12 rotate-90 md:w-2/12 md:rotate-90 lg:w-2/12 lg:rotate-90 xl:w-2/12 xl:rotate-180"
                src={copy}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <div className="p-11 lg:p-24">
        <div className="leftwhatisieee">
          <h1 className="text-4xl mb-6 font-bold lg:text-6xl  ">
            About IEEE SAC Egypt
          </h1>
          <div className="flex-col flex xl:flex-row">
            <ul className="lg:text-2xl font-thin xl:w-7/12">
              <li className={styles.leftlist}>
                <p>
                  IEEE Student Activities Committee Egypt represents and
                  coordinates with all 60 IEEE
                </p>
              </li>
              <li className={styles.leftlist}>
                <p>
                  Student Branches in Egypt. Connecting local companies and
                  experts with
                </p>
              </li>
              <li className={styles.leftlist}>
                <p>
                  IEEE Student Branches to enhance student activities within
                  Egypt. ESYP Proposal 7
                </p>
              </li>
              <li className={styles.leftlist}>
                <p>
                  Over 150 events by different IEEE Student Branches have been
                  organized in 2021/2022.
                </p>
              </li>
              <li className={styles.leftlist}>
                <p>
                  Connecting IEEE Students and Student Branches with global IEEE
                  Organizational Units for international collaboration
                </p>
              </li>
            </ul>
            <div className="rightwhatisieee flex justify-center items-center xl:w-7/12">
              <img className="md:w-8/12 xl:w-8/12 " src={sac} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="p-11 lg:p-24">
        <div className="leftwhatisieee">
          <h1 className="text-3xl mb-6 font-bold lg:text-6xl ">
            About IEEE AAST Alexandria SB
          </h1>
          <div className="flex flex-col xl:flex-row">
            <div className="xl:w-7/12">
              <ul className="lg:text-2xl font-thin">
                <li className={styles.leftlist}>
                  <p>
                    IEEE AAST Alexandria Student Branch was established in 2002,
                    celebrating 20 years of activity.
                  </p>
                </li>
                <li className={styles.leftlist}>
                  <p>15 Global IEEE Awards.</p>
                </li>
                <li className={styles.leftlist}>
                  <p>More than 300 Events over the history.</p>
                </li>
                <li className={styles.leftlist}>
                  <p>A total of more than 21,000 Attendees. </p>
                </li>
                <li className={styles.leftlist}>
                  <p>A total of more than 875 Volunteers over the history.</p>
                </li>
              </ul>

              <div className={styles.flexsection}>
                <p className={` lg:text-3xl font-semibold`}>
                  Popular events by IEEE AAST AlexSB:
                </p>
                <ul className={`${styles.flexlist} lg:text-2xl font-thin`}>
                  <li>
                    <p>Leading Your Career, Leading Your Society (LYC)</p>
                  </li>
                  <li>
                    <p>MSI Keyboard Typing Marathon (KTM)</p>
                  </li>
                  <li>
                    <p>Robotics from Zero to Hero (RZTH)</p>
                  </li>
                  <li>
                    <p>IEEE Day Egypt Gathering (2021, 2015, 2012)</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="rightwhatisieee flex justify-center items-center xl:w-6/12">
              <img className="md:w-5/12" src={sb} alt="" />
            </div>
          </div>
        </div>
      </div>
      <CarouselComponent />
      <Footer />
    </div>
  );
}
