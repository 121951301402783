import React, { Fragment } from "react";
import Footer from "../../Components/Footer/Footer";
import ProgramHeader from "./ProgramHeader";
import ProgramSchedule from "./ProgramSchedule";
import ProgramTimeLine from "./ProgramTimeLine";
export default function Program() {
  return (
    <Fragment>
      <ProgramHeader />
      <ProgramTimeLine />
      <ProgramSchedule />
      <Footer />
    </Fragment>
  );
}
