import AhmedAyman from "../../Images/Ahmed Ayman - Chairman.jpg";
import MohamedSief from "../../Images/Mohamed Sief - Program Officer.jpg";
import MohamedHatem from "../../Images/Mohamed Hatem - Fundraising Officer.jpg";
import AbdelrahmanRefaay from "../../Images/Abdelrahman Elrefaay - Technical Activities Officer.jpg";
import IbrahimLotfy from "../../Images/Ibrahim Lotfy - Vice-Chairman & Head of Logistics.jpg";
import SarahHisham from "../../Images/Sarah Hisham - Head of Software Development.jpg";
import MostafaArafa from "../../Images/Mostafa Arafa Head of Media & Marketing.jpg";
import OmarKhaled from "../../Images/Omar Khaled - Vice-Head of Media _ Marketing (1).jpg";
import DanahKhedr from "../../Images/Danah Khedr - Secretary & Head of Registration.jpg";
import NadaKhedr from "../../Images/Nada Khedr - Head of Content Writing.jpg";
import TarekMohamed from "../../Images/Tarek Mohamed.jpg";

const team = [
  AhmedAyman,
  IbrahimLotfy,
  DanahKhedr,
  MohamedSief,
  MohamedHatem,
  AbdelrahmanRefaay,
  SarahHisham,
  MostafaArafa,
  OmarKhaled,
  NadaKhedr,
  TarekMohamed,
];
export default team;
