import React from "react";
import timeline from "../../Images/Timeline.png";
import style from "./program.module.css";
export default function ProgramTimeLine() {
  return (
    <div className={`p-28 ${style.section2}`}>
      <h1 className={`font-bold text-7xl ml-24 mb-11  ${style.programTitle}`}>
        Timeline
      </h1>
      <img className={style.programTimeLineImage} src={timeline} alt="" />
    </div>
  );
}
