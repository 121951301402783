import { Fragment } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Program from "./Pages/Program/Program";
import Sponsors from "./Pages/Sponsors/Sponsors";
import FAQ from "./Pages/FAQ/FAQ";
import Navbar from "./Components/Navbar/Navbar";

function App() {
    return (
        <Fragment>
            <Navbar />
            <Routes>
                <Route element={<Home />} path="/" />
                <Route element={<About />} path="/about" />
                <Route element={<Program />} path="/program" />
                <Route element={<Sponsors />} path="/sponsors" />
                <Route element={<FAQ />} path="/faq" />
            </Routes>
        </Fragment>
    );
}

export default App;
