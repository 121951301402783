import React from "react";
import styles from "./head.module.css";

const Head = () => {
  return (
    <div className={styles.head}>
      <h1 className={` font-bold text-8xl text-center text-white`}>About Us</h1>
    </div>
  );
};

export default Head;
