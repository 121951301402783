import React from "react";
import Header from "../../Images/FAQ.png";
import faq from "./FAQ.module.css";
import Footer from "../../Components/Footer/Footer";

export default function FAQ() {
  return <div>
    <div className={faq.faqimage}>
      <div className={faq.Header}>FAQ</div>
    </div>
    <div className={faq.Content}>
    <div className={faq.Topic}>Where will the accommodation be?</div>
    <div className={faq.Details}>Within the dorms of the AASTMT Main Campus in Alexandria.</div>

    <div className={faq.Topic}>Is there a limit to how many volunteers I can bring from my Student Branch?</div>
    <div className={faq.Details}>Nope, all your Student Branch's volunteers are welcome to register, however, to reserve their seat, they must complete the payment and confirmation form. Once all seats are full, we will close the forms and send an apology email to all who couldn't confirm early enough. That is why it is recommended to hurry up and confirm your registration before seats are full.</div>

    <div className={faq.Topic}>What is the payment method?</div>
    <div className={faq.Details}>Vodafone Cash. You can either complete the transaction via your mobile directly to the Vodafone Cash number or find a supermarket that has a Fawry device. Please, in both cases, call the number and make sure you are on the call while paying and take a picture of the physical receipt or screenshot of the digital receipt to submit it in the confirmation form. The Vodafone Cash number can be found after completing the Registration form or in the description of the Confirmation form.
    <div>
    • Registration form: <a className={faq.a} href="https://esyp.ieee.org.eg/register">https://esyp.ieee.org.eg/register</a>
    </div>
    <div>
    • Confirmation form: <a className={faq.a} href="https://esyp.ieee.org.eg/confirm">https://esyp.ieee.org.eg/confirm</a>
    </div>
    </div>

    <div className={faq.Topic}>Is there a dress code?</div>
    <div className={faq.Details}>Yes, there will be three dress codes:
<div>• Formal: Opening & Closing Ceremonies, Gala Dinner</div>
<div>• Semi-Casual: Workshops, Keynotes, Plenary Sessions</div>
<div>• Casual: Poster Session, City Tour</div>
<div>The ESYP'22 T-Shirt you will receive is considered Casual.</div></div>


    <div className={faq.Topic}>Can I view the Program Schedule, Speakers list, and some more details about the ESYP'22?</div>
    <div className={faq.Details}>For sure, please visit <a className={faq.a} href="https://esyp.ieee.org.eg/details">https://esyp.ieee.org.eg/details</a></div>

    <div className={faq.Topic}>Are there ESYP'22 Social Media pages that I can follow to stay updated?</div>
    <div className={faq.Details}><div>
    - Facebook: <a className={faq.a} href="https://www.facebook.com/EgyptSYP/">https://www.facebook.com/EgyptSYP/</a>
    </div>
    <div>
    - Instagram: <a className={faq.a} href="https://www.instagram.com/egyptsyp/">https://www.instagram.com/egyptsyp/</a>
    </div>
    <div>
    - Linkedin: <a className={faq.a}  href="https://www.linkedin.com/company/esyp/">https://www.linkedin.com/company/esyp/</a>
    </div>
    </div>

    <div className={faq.Topic}>I have a friend who is not an IEEE volunteer or member, can he register?</div>
    <div className={faq.Details}>Sadly not, this congress is mainly for IEEE volunteers and members to gather, network, share their experiences, and learn from IEEE experts.</div>

    <div className={faq.Topic}>Where exactly will the transportation buses take us from in Cairo?</div>
    <div className={faq.Details}>That is not yet decided, however, there will be multiple busses so we will consider placing them in different locations in case demanded.</div>

    <div className={faq.Topic}>I need help or to ask some questions, how can I contact you?</div>
    <div className={faq.Details}>Please do not hesitate to email <a className={faq.a} href="mailto:esyp@ieee.org.eg">esyp@ieee.org.eg</a> for any inquiries.</div>

    </div>
    <Footer />
  </div>;
}
