import React, { useEffect, useState, useRef } from "react";
import Logo from "../../Images/ESYP-logo-1 (1) 1.png";
import home from "./home.module.css";
import WiESYPImage from "../../Images/Group 1.png";
import Objective from "../../Images/objectiveIcon.png";
import Footer from "../../Components/Footer/Footer";
import moment from "moment";
import { Link } from "react-router-dom";

const WiESYP =
  "The IEEE Egyptian Student and Young Professional Congress is the largest IEEE congress to happen in Egypt every year, with 2022 being its first version. It gathers students and young professional members from all around Egypt to network, share ideas, and learn from expert IEEE speakers coming from Egypt and Region 8 (EMEA) as well as expert speakers from the industry.";

export default function Home() {
  const calculateTimeLeft = () => {
    let difference = +new Date("September 8, 2022 08:00:00") - +new Date();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    timerComponents.push(
      <div className="flex flex-col mx-4 font-semibold">
        <span className=" text-4xl sm:text-5xl md:text-7xl lg:text-7xl xl:text-7xl">
          {timeLeft[interval]}
        </span>
        <span>{interval}</span>
      </div>
    );
  });
  return (
    <div>
      <div className={home.headercontainer}>
        <div className={home.Headerr}></div>
        <img src={Logo} className={home.Logo} alt="Logo"></img>
        <div className={home.Titlecontainer}>
          <div className={home.Title}>IEEE Egyptian SYP Congress 2022</div>
        </div>
        <div className={home.Button}>
          <Link
            className="absolute translate-y-[-50%] translate-x-[-50%] bg-[#4F2700] hover:bg-[#4F2700] font-['Poppins'] font-light text-white px-10 py-4 font-bold rounded-[6px] top-[75%] left-[50%] lg:left-[40%] hover:scale-105 transition ease-in "
            to="/program"
          >
            Program
          </Link>
          <a
            className="absolute translate-y-[-50%] translate-x-[-50%] bg-[#FFFFFF] hover:bg-[#FFFFFF] font-['Poppins'] font-light text-black px-10 py-4 font-bold rounded-[6px] top-[85%] left-[50%] lg:left-[60%] lg:top-[75%] hover:scale-105 transition ease-in "
            href="https://esyp.ieee.org.eg/register"
            target="_blank"
            rel="noreferrer"
          >
            Register
          </a>
        </div>
      </div>
      <div className={home.Content}>
        <div class="grid grid-cols-1 lg:grid-cols-2 pb-40">
          <div>
            <div className={home.Topic}>What is IEEE ESYP?</div>
            <div className={home.Details}>{WiESYP}</div>
            <Link
              className="bg-[#393939] font-['Poppins'] font-bold text-white px-10 py-4 rounded-[6px] hover:bg-zinc-700 tranistion ease-in hover:scale-105"
              to="/program"
            >
              Explore
            </Link>
          </div>
          <div className={home.imagecontainer}>
            <img src={WiESYPImage} className={home.imagecontent} alt=""></img>
          </div>
        </div>
        <div className={home.Topic}>Objectives</div>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-10 pt-20 pb-40">
          <div className={home.objectcontainer}>
            <img src={Objective} className={home.object} alt=""></img>
            <div className={home.objectcontent}>
              Foster network between academia, industry and government.
            </div>
          </div>
          <div className={home.objectcontainer}>
            <img src={Objective} className={home.object} alt=""></img>
            <div className={home.objectcontent}>
              Educate and prepare tomorrow’s tech leader from current
              generations.
            </div>
          </div>
          <div className={home.objectcontainer}>
            <img src={Objective} className={home.object} alt=""></img>
            <div className={home.objectcontent}>
              Feature Green Tech impact in the current ecosystem.
            </div>
          </div>
          <div className={home.objectcontainer}>
            <img src={Objective} className={home.object} alt=""></img>
            <div className={home.objectcontent}>
              Pave career paths for university students in tech ecosystem.
            </div>
          </div>
          <div className={home.objectcontainer}>
            <img src={Objective} className={home.object} alt=""></img>
            <div className={home.objectcontent}>
              Boost the tech-entrepreneurial mindset for Egyptian youth.
            </div>
          </div>
          <div className={home.objectcontainer}>
            <img src={Objective} className={home.object} alt=""></img>
            <div className={home.objectcontent}>
              Discuss the ongoing advancements in technology and share
              experiences.
            </div>
          </div>
          <div className={home.objectcontainer}>
            <img src={Objective} className={home.object} alt=""></img>
            <div className={home.objectcontent}>
              Bring altogether all IEEE members and volunteers all over Egypt.
            </div>
          </div>
        </div>
        <div className={home.Topic}>Countdown Timer</div>
        <div class="pt-20 pb-20">
          <div className="flex w-full justify-center items-center text-center">
            {timerComponents.length ? timerComponents : <p>Time's up!</p>}
          </div>
        </div>
        <div className={home.Topic}>Location</div>
        <div className={home.Details}>
          Arab Academy For Science, Technology & Maritime Transport - Abu Kir
          Branch
        </div>
        <div style={{ "margin-bottom": 40 }}>
          <iframe
            title="AAST Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2686.0839775421964!2d30.06374331581033!3d31.309901662068462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1526add103b155ef%3A0xed5db8d49172beb5!2sArab%20Academy%20For%20Science%2C%20Technology%20%26%20Maritime%20Transport!5e0!3m2!1sen!2seg!4v1660950413548!5m2!1sen!2seg"
            width="1357"
            height="487"
            style={{ border: "0" }}
            allowfullscreen=""
          ></iframe>
        </div>
      </div>
      <Footer />
    </div>
  );
}
