import React from "react";
import style from "./program.module.css";
export default function ProgramHeader() {
  return (
    <div className={`${style.programHeader} flex justify-center items-center`}>
      <div>
        <h1
          className={`text-white text-9xl font-semibold ${style.programTitle}`}
        >
          Program
        </h1>
      </div>
    </div>
  );
}
