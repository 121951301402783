import React from "react";
import style from "./program.module.css";
export default function ProgramSchedule() {
  return (
    <div className="  flex flex-col items-center justify-center mb-24 p-1 text-sm md:p-1 xl:p-11  overflow-x-hidden">
      <h1 className={`font-bold text-7xl ml-24 my-11  ${style.programTitle}`}>
        Schedule
      </h1>
      <table className="w-9/12 shadow-xl" border={true}>
        <tr className="">
          <th colSpan={4} className="p-5 shadow-md">
            ESYP 2022 - Tentative Schedule
          </th>
        </tr>
        <tr>
          <th></th>
          <th>
            Day 1 <br /> Thursday (8th Sep)
          </th>
          <th>
            Day 2 <br /> Friday (9th Sep)
          </th>
          <th>
            Day 3 <br /> Saturday (10th Sep)
          </th>
        </tr>
        <tbody>
          <tr>
            <td>07:00-09:00</td>
            <td rowSpan={5}>
              Buses Move from <br />
              Ramisis to AASTMT <br />
              Alamein <br />
              (Buses move at 08:00 AM)
            </td>
            <td className="bg-yellow-100">Breakfast</td>
            <td className="bg-yellow-100 shadow-sm">Breakfast</td>
          </tr>
          <tr>
            <td>09:00-10:00</td>
            <td className="bg-sky-200">Plenary Session I</td>
            <td className="bg-sky-200">Volunteer Training Workshop</td>
          </tr>
          <tr>
            <td>10:00-11:00</td>
            <td className="bg-sky-200">Plenary Session II</td>
            <td className="bg-sky-200">Four Parallel Technical Workshops II</td>
          </tr>
          <tr>
            <td>11:00-12:00</td>
            <td className="bg-sky-200">Three Parallel Keynotes I </td>
            <td className="bg-sky-200">
              Four Parallel Technical Workshops III
            </td>
          </tr>
          <tr>
            <td>12:00-13:00</td>
            <td className="bg-green-200">Break</td>
            <td>
              Preparation for Closing Ceremony <br /> (Formal dress code)
            </td>
          </tr>
          <tr>
            <td>13:00-14:00</td>
            <td>
              Preparation for Opening <br /> Ceremony
            </td>
            <td className="bg-sky-200">
              Four Parallel Technical <br /> Workshops I
            </td>
            <td className="bg-orange-200">Closing Ceremony</td>
          </tr>
          <tr>
            <td>14:00-15:00</td>
            <td className="bg-yellow-100">Lunch</td>
            <td className="bg-yellow-100">Lunch</td>
            <td>Checkout</td>
          </tr>
          <tr>
            <td>15:00-16:30</td>
            <td className="bg-yellow-200">Opening Ceremony</td>
            <td className="bg-purple-100">Poster Session</td>
            <td className="bg-green-200">Buses Move to Ramisis</td>
          </tr>
          <tr>
            <td>16:30-17:30</td>
            <td rowSpan={3} className="bg-sky-200">
              City Tour in Alamein <br /> City
            </td>
            <td className="bg-sky-200">Panel Discussion I</td>
          </tr>
          <tr>
            <td>17:30-18:00</td>
            <td className="bg-yellow-100">Coffee Break</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td className="bg-sky-200">Panel Discussion II</td>
          </tr>
          <tr>
            <td>19:30-21:00</td>
            <td>
              Buses Move from Alamein <br /> to AASTMT Alexandria
              <br /> (Buses move at 08:00PM)
            </td>
            <td className="bg-sky-200">Key Notes II </td>
          </tr>
          <tr>
            <td>21:00-22:00</td>
            <td className="bg-yellow-100">Check-in &amp; Dinner</td>
            <td className="bg-yellow-100">Gala Dinner</td>
          </tr>
          <tr>
            <td>Night Activity</td>
            <td colSpan={2} className="text-center bg-green-200">
              Free Time (Games) <br /> + Movie Night
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
