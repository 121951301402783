import React from "react";
import "pure-react-carousel/dist/react-carousel.es.css";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image,
} from "pure-react-carousel";
import team from "./team";
export default function CarouselComponent() {
  return (
    <section className="p-11 xl:p-32">
      <h1 className="text-3xl mb-6 font-bold lg:text-6xl ">Our Team</h1>
      <CarouselProvider
        naturalSlideWidth={500}
        naturalSlideHeight={500}
        totalSlides={13}
        visibleSlides={1}
        currentSlide={1}
        isPlaying={true}
        interval={1000}
      >
        <Slider>
          {team.map((img, index) => {
            return (
              <Slide index={index}>
                <Image src={img} className="border-8" />
              </Slide>
            );
          })}
        </Slider>
        <div className="flex justify-around mt-11">
          <ButtonBack className="bg-zinc-900 font-bold text-white px-10 py-4 rounded-[6px] hover:bg-zinc-700 tranistion ease-in hover:scale-105">
            Back
          </ButtonBack>
          <ButtonNext className=" bg-zinc-900 font-bold text-white px-10 py-4 rounded-[6px] hover:bg-zinc-700 tranistion ease-in hover:scale-105">
            Next
          </ButtonNext>
        </div>
      </CarouselProvider>
    </section>
  );
}
